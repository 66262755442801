<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Group List</h4>
          <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leadership-groups/create'])"
                       :to="'/leadership-groups/create/'+lang"
                       class="btn btn-info btn-wd" wide>
            Add New
            <span class="btn-label">
                    <i class="fa fa-plus"></i>
                </span>
          </router-link>
        </div>
        <div>
          <general-data-table
            ref="table"
            :lang="lang"
            :key="componentKey"
            @reorder="reorder($event)"
            :api-url="'leadership-groups/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              :min-width="120"
              align="center"
              label="Active">
              <template slot-scope="props">

                <l-switch v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leadership-groups/change-active-status']) ||
                                $store.getters['auth/checkAccessOwner']('restricted_own/ar/leadership-groups/change-active-status', props.row.creator_id)"
                          v-model="props.row.is_active" @input="handleActiveStatus(props.row)">
                  <i class="fa fa-check" slot="on"></i>
                  <i class="fa fa-times" slot="off"></i>
                </l-switch>

              </template>
            </el-table-column>
            <el-table-column
              :min-width="120"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leadership-groups/update']) ||
                                     $store.getters['auth/checkAccessOwner']('restricted_own/ar/leadership-groups/update', scope.row.creator_id)"
                               v-tooltip.top-center="'Edit'"
                               class="btn-warning btn-simple btn-link"
                               :to="'/leadership-groups/edit/'+scope.row.id+'/'+lang">
                    <i class="fa fa-edit"></i>
                  </router-link>

                  <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['leaderships_groups_ar_logs_view'])"
                                v-tooltip.top-center="'Log'"
                                class="btn-info btn-simple btn-link"
                                :to="'/logs/leaderships_groups_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['ar/leadership-groups/delete']) ||
                           $store.getters['auth/checkAccessOwner']('restricted_own/ar/leadership-groups/delete', scope.row.creator_id)"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this group?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import {Switch as LSwitch} from '@/components'


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
  },

  data() {

    return {
      tableColumns: [
        {label: 'Group Title', value: 'title', minWidth: '200', align: 'center'},
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'ar',
      componentKey: 0,
    }
  },
  mounted() {
    this.$refs.table.createDragAndDropSorting();
  },
    created(){
        const path = window.location.href;
        this.lang = path.substring(path.lastIndexOf('/') + 1)
    },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        'lang': this.lang,
      }
      this.axios.post("leadership-groups/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Group updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
            'id': this.toDeleteId,
            'lang': this.lang,
        }
        await this.axios.delete("leadership-groups/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Group deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          lang: this.lang,
          data: orderData,
        }

        await this.axios.post("leadership-groups/re-order", body);
        this.componentKey ++;
        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },

  },
}
</script>

